import React from 'react'

import Container from 'react-bootstrap/Container'
import Row from 'react-bootstrap/Row'
import Col from 'react-bootstrap/Col'
import { motion, AnimateSharedLayout } from "framer-motion"

import {
    Subtitle,   
} from './style.js'
import RichText from '../shared/RichText'

import PrayerRequestFormImg from './prayer-request-img'

const PrayerRequestSection1 = (data) => {
    
    
    return (
        <div>
            <section>
                <Container>
                    <Row>
                        <Col>
                            <Subtitle>{data?.data?.contentfulAPrayerRequestContainer?.subtitle}</Subtitle>
                        </Col>
                    </Row>
                    <Row className='justify-content-center'>
                        <Col xs={12} sm={11} md={10} lg={8} >
                            {<RichText raw={data?.data?.contentfulAPrayerRequestContainer?.text?.raw} />}
                        </Col>
                    </Row>
                </Container>
            </section>

            <PrayerRequestFormImg />

            <section>
                <Container>
                    <Row className='justify-content-center'>
                        <Col xs={12} sm={11} md={10} lg={8}>
                            <AnimateSharedLayout>
                                <motion.div
                                    layout
                                    transition={{ duration: .4 }}
                                >{<RichText raw={data?.data?.contentfulAPrayerRequestContainer?.prayWithYou?.raw} />}
                                </motion.div>
                            </AnimateSharedLayout>
                        </Col>
                    </Row>
                </Container>     
            </section>
        </div>
    )
}
export default PrayerRequestSection1