import styled from 'styled-components'


export const HeroContainer = styled.div`
    position:relative;
    border:2pa solid red;
`;

export const TitleContainer = styled.div`
    position:absolute;
    top:60%;
    left:50%;
    transform: translate(-50%, -50%);
    -ms-transform: translate(-50%, -50%);
`;

export const Title = styled.h1`
    text-align:center;
    letter-spacing: 7px;
    color: ${({ theme }) => theme.colors.mediumRedViolet};
    font-waight: bold;
    border-radius:5px;
    padding:.8rem;
    background-color:rgba(255, 255, 255, .5)!important;
    text-shadow:.3px .2px .1px #000000;
    @media only screen and (max-width: 600px){
    font-size:4vw;
    letter-spacing: 1vw;
};
`;

export const Subtitle = styled.h4`
    margin-bottom: 3rem;
    text-align: center;
    color: ${({ theme }) => theme.colors.mediumRedViolet};
    text-transform: uppercase;
`;

export const CenterButton = styled.div`
text-align:center;
margin-top:3rem;
margin-bottom:3rem;
`;

export const SyledBtnOpen = styled.button`
border: 1.5px solid ${({ theme }) => theme.colors.mediumRedViolet};
color: ${({ theme }) => theme.colors.mediumRedViolet};
border-radius:3px;
padding-left:1rem;
padding-right:1rem;
`;

export const SyledBtnClose = styled(SyledBtnOpen)`
border: 1.5px solid ${({ theme }) => theme.colors.royalBlue};
color: ${({ theme }) => theme.colors.royalBlue};
`;

export const BgForm = styled.div`
border: 1.5px solid ${({ theme }) => theme.colors.royalBlue};
border-radius: 10px;
background:${({ theme }) => theme.colors.mediumRedViolet};
opacity:.3;
z-index:3;
`;