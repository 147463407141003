import React from 'react'
import { graphql, useStaticQuery } from 'gatsby';

import PrayerRequestHero from './prayer-request-hero'
import PrayerRequestSection1 from './prayer-request-section1'

const PrayerRequestMain = () => {

    const data = useStaticQuery(graphql`
    query PrayerRequestQuery {
          contentfulAPrayerRequestContainer {
             title
             imageTop {
              gatsbyImageData(
                layout: FULL_WIDTH
                placeholder: BLURRED
                backgroundColor: "#ffb900"
                aspectRatio: 2
                quality: 70
              )
            }
             subtitle
                text {
                  raw
                }
                prayWithYou {
                  raw
                }
        }
    }
  `);

    return (<>        
        <PrayerRequestHero data={data} />
        <PrayerRequestSection1 data={data}/>
    </>)
}
export default PrayerRequestMain