import React from 'react';
import { theme } from '../styles/theme';
import { ThemeProvider } from '../styles/ThemeProvider';
import Layout from '../components/Layout';
import Head from '../components/Head';
import PrayerRequestMainComponent from '../components/prayer-request/prayer-request-main';
import WholePageOpacity from '../animations/whole-page-opacity';
const PrayerRequest = () => {
  return (
    <ThemeProvider theme={theme}>
      <Layout>
        <Head title="Prayer Request | GWOS" />
        {/* <div type="hidden">
          <form
            type="hidden"
            name="gwos-request"
            method="post"
            data-netlify="true"
            onSubmit="submit">
            <input type="hidden" name="form-name" value="gwos-request" />
            <select type="hidden" name="title[]"></select>
            <select type="hidden" name="category[]"></select>

            <input type="hidden" name="first name" />
            <input type="hidden" name="last name" />
            <input type="hidden" name="email" />
            <input type="hidden" name="phone" />
            <textarea type="hidden" name="request message" />
            <button type="submit">Submit</button>
          </form>
        </div> */}
        <WholePageOpacity>
          <PrayerRequestMainComponent />
        </WholePageOpacity>
      </Layout>
    </ThemeProvider>
  );
};

export default PrayerRequest;
