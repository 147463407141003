import React from 'react'
import Container from 'react-bootstrap/Container'
import Row from 'react-bootstrap/Row'
import Col from 'react-bootstrap/Col'
import { getImage, GatsbyImage } from "gatsby-plugin-image"

import {
    HeroContainer,
    TitleContainer,
    Title
} from './style.js'


const PrayerRequestHero = (data) => {    

    return (
        <section>
            <Container fluid className='p-0'>
                <Row className='p-0'>
                    <Col>
                        <div>
                            <HeroContainer>{data?.data?.contentfulAPrayerRequestContainer?.imageTop !== undefined ?
                                <GatsbyImage image={getImage(data?.data?.contentfulAPrayerRequestContainer?.imageTop !== undefined ?
                                    data?.data?.contentfulAPrayerRequestContainer?.imageTop.gatsbyImageData :
                                    null)} alt={data?.data?.contentfulAPrayerRequestContainer.title !== null ||
                                        data?.data?.contentfulAPrayerRequestContainer.title !== undefined ?
                                        `${data?.data?.contentfulAPrayerRequestContainer.title}` : 'image'} /> :
                                null}
                                <TitleContainer><Title>{data?.data?.contentfulAPrayerRequestContainer.title}</Title></TitleContainer>
                            </HeroContainer>
                        </div>
                    </Col>
                </Row>
            </Container>
        </section>
    )
}
export default PrayerRequestHero