import React, { useState } from 'react';
import { graphql, useStaticQuery } from 'gatsby';
import { getImage } from 'gatsby-plugin-image';

import { convertToBgImage } from 'gbimage-bridge';
import BackgroundImage from 'gatsby-background-image';
import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import { motion, AnimatePresence, AnimateSharedLayout } from 'framer-motion';
import loadable from '@loadable/component';

import { SyledBtnOpen, SyledBtnClose, CenterButton } from './style';
//import FormPrayerRequest from '../Forms/formPrayerRequest'
const FormPrayerRequest = loadable(() => import('../Forms/formPrayerRequest'));

const PrayerRequestFormImg = () => {
  const { placeholderImage } = useStaticQuery(
    graphql`
      query {
        placeholderImage: file(relativePath: { eq: "prayer-request-form.jpeg" }) {
          childImageSharp {
            gatsbyImageData(quality: 99, layout: FULL_WIDTH, aspectRatio: 2)
          }
        }
      }
    `,
  );

  const image = getImage(placeholderImage);
  const bgImage = convertToBgImage(image);

  const [open, setOpen] = useState(true);

  const handleOpen = e => {
    e.preventDefault();
    setOpen(!open);
  };

  return (
    <section>
      <AnimateSharedLayout>
        <motion.div layout transition={{ duration: 0.3 }} exit={{ duration: 2 }}>
          <BackgroundImage Tag="section" {...bgImage} preserveStackingContext>
            <Container>
              <Row>
                <Col>
                  <FormPrayerRequest />
                </Col>
              </Row>
            </Container>
          </BackgroundImage>
        </motion.div>
      </AnimateSharedLayout>
    </section>
  );
};
export default PrayerRequestFormImg;
